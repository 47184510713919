<template>
  <b-container>
    <b-alert
      show
      class="text-center"
      v-if="getted"
      style="padding: 5px 10px"
      :variant="variant"
    >
    <span v-html="message"></span>
    </b-alert>
    <b-skeleton v-else></b-skeleton>
    <div>
      <DashApps />
    </div>
    <div>
      <TopChartsVue />
    </div>
  </b-container>
</template>
<script>
import DashApps from "@/components/user-dashboard/apps/DashApps.vue";
import { BContainer, BRow, BAlert, BSkeleton } from "bootstrap-vue";
import TopChartsVue from "@/components/user-dashboard/topCharts/TopCharts.vue";
import axios from "@axios";
export default {
  components: {
    BContainer,
    BRow,
    DashApps,
    TopChartsVue,
    BAlert,
    BSkeleton,
  },
  data() {
    return {
      message: "",
      variant: "",
      getted: false,
    };
  },
  mounted(){
    this.checkPlanExpiration();
  },
  methods: {
    checkPlanExpiration() {
      axios
        .post("/plan-expiration")
        .then(({ data }) => {
          if (data.plan === "basic") {
            if (data.deleted === true) {
              this.variant = "warning";
              this.message =
                `You have unpaid invoices 📝, <a class="payment" href="/pages/payment-method?same_id=${data.invoice_id}&plan_id=1">Pay your invoice </a> now to unlock Pro functions`;
            this.createInvoice();
            } else {
              this.variant = "danger";
              this.message =
                `You are in the basic plan, <a href="/pages/plan-selection"> Upgrade 🚀 </a> your account to unlock Pro Functions`;
            }
          }
          if (data.plan === "pro") {
            this.variant = "success";
            this.message =
              "Gongratultions 🎉, you've successfully upgraded your plan to use all Pro Functions";
          }
          
          this.getted = true;
        })
        .catch((this.getted = true));
    },
    createInvoice() {
    axios
      .post("/invoices",{ amount_total: 0.99})
      .then(({ data }) => {})
      .catch(console.error);
  },
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.links {
  color: inherit;
}
</style>
