<template>
  <div>
    <b-row v-if="isLoading">
      <b-col>
        <b-card no-body style="padding:10px 6px">
          <b-skeleton animation="wave" width="100%"></b-skeleton>
          <b-skeleton animation="wave" width="100%"></b-skeleton>
        </b-card>
      </b-col>

    </b-row>
    <div v-else>
      <b-row class="justify-content-between mb-1">
        <b-col sm="5">

        </b-col>
        <b-col sm="5" md="4">
          <div class="text-right">
            <b-button class="p-0" variant="outline-success" to="/myprofile/apps/creation" style="padding:6px !important;">
              <i class="ri-add-box-fill"></i>Add App
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="p-1 p-sm-2">
              <b-row class="align-items-center">
                <b-col  cols="4" class="d-none d-sm-block">
                  <b-card-title class="mb-1">My Dashboard</b-card-title>
                  <b-card-title class="mb-1">Apps & Games</b-card-title>
                </b-col>
                <b-col cols="12" class="d-sm-none d-block">
                  <b-card-title class="mb-1">My Dashboard : Apps & Games</b-card-title>
                </b-col>

                <b-col v-for="item in statisticsItems" :key="item.icon" sm="12" md="4" class="mb-2 mb-md-0"
                  :class="item.customClass">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" :variant="item.color">
                        <i :class="item.icon" style="font-size:1.6rem !important"></i>
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ item.title }} <b-link :to="item.link" :class="`${item.arrowIcon} text-primary`"
                          style="font-size:1.3rem !important;font-weigth:900"></b-link>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ item.subtitle }} : <span style="font-size:1.4rem;font-weight:bold ;"> {{ item.number }}</span>
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            
          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BSkeleton,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BCardText,
  BCardTitle,
  BLink
} from 'bootstrap-vue';
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSkeleton,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardText,
    BCardTitle,
    BLink

  },
  data() {
    return {
      items: null,
      totalApps: null,
      isLoading: false,
      statisticsItems: [],
      totalApps: null,
      totalAccounts: null,
    }
  },
  mounted() {
    this.showAllApps();
  },
  watch: {
  },
  computed: {
  },
  methods: {
    showAllApps() {
      this.isLoading = true;
      this.$store
        .dispatch("appsManager/getAllApps")
        .then(({ data }) => {
          if (data) {
            this.totalApps = data.apps.length;
          } else {
            this.totalApps = 0;
          }
        })
        .then(async () => {
          await this.showAllAccounts();
          this.statisticsItems = [
            {
              color: "primary",
              icon: "ri-apps-line",
              title: "Accounts",
              subtitle: "Number of saved accounts",
              number: this.totalAccounts,
              arrowIcon: "ri-arrow-right-up-line",
              // link: "/myprofile/accounts"
            },
            {
              color: "primary",
              icon: "ri-user-settings-line",
              title: "Apps",
              subtitle: "Number of saved apps",
              number: this.totalApps,
              arrowIcon: "ri-arrow-right-up-line",
              link: "/myprofile/apps"
            }
          ];

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },

    async showAllAccounts() {
      try {
        const { data } = await this.$store.dispatch("appsManager/getAllAccounts");
        if (data) {
          this.totalAccounts = data.accounts.length;
        } else {
          this.totalAccounts = 0;
        }
      } catch (error) {
        console.error(error);
      }
    }


  },
};
</script>

<style scoped>
.appsDashIcon1 {
  font-size: 1.5rem;
}

.nameApp {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accountName {
  font-size: 12px !important;
}

.scoreApp {
  position: absolute;
  top: 0;
  right: 0;
}
</style>