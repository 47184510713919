<template>
  <b-row cols="12">
    <b-col sm="12" md="6" lg="4">
      <TopNewOverall />
    </b-col>
    <b-col sm="12" md="6" lg="4">
      <TopNewApps />
    </b-col>
    <b-col sm="12" md="12" lg="4">
      <TopNewGames />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import TopNewOverall from "./TopNewOverall.vue";
import TopNewApps from "./TopNewApps.vue";
import TopNewGames from "./TopNewGames.vue";
export default {
  name: "TopCharts",
  components: {
    BRow,
    BCol,
    TopNewOverall,
    TopNewApps,
    TopNewGames,
  },
};
</script>