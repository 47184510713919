<template>
  <b-card no-body style="padding: 10px; position: relative">
    <b-card-title> Top Games </b-card-title>
    <b-table
      v-if="!progress"
      class="w-100"
      responsive
      hover
      :items="items"
      thead-class="d-none"
      :fields="fields"
      show-empty
    >
      <template #empty>
        <div class="text-center">
          <feather-icon icon="AlertTriangleIcon" size="45" />
          <h4 class="mt-1">Data Being Prepared</h4>
        </div>
      </template>
      <template #cell(title)="data">
        <div style="position: relative" class="w-100 d-flex align-items-center">
          <div>
            <b-link :to="'/myprofile/apps/' + data.item.app_id + ',api,0/info'">
              <img :src="data.item.icon" width="60" />
            </b-link>
          </div>
          <div class="ml-1">
            <p :title="data.value" class="nameApp">
              {{ data.value }}
            </p>
            <a
              :href="`/market-manager/accounts-search?search=${data.item.account}`"
              :title="data.item.account"
              class="accountName nameApp"
            >
              By: {{ data.item.account }}
            </a>
          </div>
          <div class="scoreApp">
            <span class="badge bg-primary rounded-pill">
              {{ data.value ? `${data.item.score} /5` : 0 }}
            </span>
          </div>
        </div>
      </template>
    </b-table>
    <div class="text-center my-4" v-if="progress">
      <b-spinner
        class="spinner"
        variant="primary"
        size="60"
        label="Text Centered"
      />
    </div>
    <b-link
      to="/top-charts/top"
      style="position: absolute; bottom: 5px; right: 5px"
    >
      <feather-icon icon="ArrowRightCircleIcon" size="23" />
    </b-link>
  </b-card>
</template>

<script>
import moment from "moment";
import axios from "@axios";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BTr,
  BTd,
  BSpinner,
  BCardTitle,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BTr,
    BTd,
    BSpinner,
    BCardTitle,
    BLink,
  },
  data() {
    return {
      progress: false,
      countryValue: "ma",
      fields: [
        {
          key: "title",
          label: "",
        },
      ],
      items: [],
    };
  },
  mounted() {
    this.getApps();
  },
  methods: {
    getApps() {
      this.items = [];
      this.progress = true;
      axios
        .get(
          `/get-market-apps?country=${this.countryValue}&date=${moment().format(
            "YYYY-MM-DD"
          )}&chart=top_new_apps&category=GAME`
        )
        .then((response) => response.data)
        .then((data) => {
          this.progress = false;
          if (data.count > 0) {
            this.isDatabase = true;
            let apps = data.apps.sort((a, b) => {
              return a.position - b.position;
            });
            this.displayTableContent(apps.slice(0, 8));
          }
        })
        .catch(console.error);
    },
    displayTableContent(results) {
      this.items = results;
    },
  },
};
</script>
<style scoped>
.nameApp {
  display:block;
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accountName {
  font-size: 12px !important;
}
.scoreApp {
  position: absolute;
  top: 0;
  right: 0;
}
</style>